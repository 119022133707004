<template>
  <div>
    <ServerError v-if="ServerError" />

    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-container v-if="userInfo" py-10>
      <v-form ref="form">
        <v-layout wrap justify-center>
          <v-flex xs12 lg12>
            <v-card tile>
              <!-- <v-layout wrap justify-center>
                <v-flex>
                  <v-card flat v-if="coverImage == null">
                    <v-layout wrap justify-center pa-7>
                      <v-flex xs10 sm2 text-center>
                        <v-avatar size="140">
                          <v-card elevation="5">
                            <v-img
                              height="143px"
                              cover
                              :src="require('./../../assets/Images/bg.jpg')"
                              v-if="profileImage == null"
                            >
                              <Propic
                                @stepper="profilewinStepper"
                                :height="'811'"
                                :width="'811'"
                                :heading="'Upload Profile Picture'"
                                :componentType="'coverImage'"
                              />
                            </v-img>

                            <v-img
                              contain
                              height="143px"
                              cover
                              :src="pic2"
                              v-else
                            >
                              <Propic
                                @stepper="profilewinStepper"
                                :height="'811'"
                                :width="'811'"
                                :heading="'Upload Profile Picture'"
                                :componentType="'coverImage'"
                              />
                            </v-img>
                          </v-card>
                        </v-avatar>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-card v-else>
                    <v-layout wrap justify-center pa-7>
                      <v-flex xs10 sm2 text-center>
                        <v-avatar size="140">
                          <v-card elevation="5">
                            <v-img
                              height="143px"
                              cover
                              :src="require('./../../assets/Images/bg.jpg')"
                              v-if="profileImage == null"
                            >
                              <Propic
                                @stepper="profilewinStepper"
                                :height="'811'"
                                :width="'811'"
                                :heading="'Upload Profile Picture'"
                                :componentType="'coverImage'"
                              />
                            </v-img>
                            <v-img height="143px" cover :src="pic2" v-else>
                              <Propic
                                @stepper="profilewinStepper"
                                :height="'811'"
                                :width="'811'"
                                :heading="'Upload Profile Picture'"
                                :componentType="'coverImage'"
                              />
                            </v-img>
                          </v-card>
                        </v-avatar>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout> -->
              <v-layout wrap justify-center>
                <v-flex xs12 pt-6>
                  <span class="sofiaPro">Customer Details</span>
                </v-flex>
                <v-flex xs3 pb-4>
                  <v-progress-linear
                    height="2"
                    value="100"
                    color="#D8D6D6DE"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center v-if="sellerDet">
                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">First Name</span>

                  <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.firstname"
                    placeholder="First Name"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">Last Name</span>

                  <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.lastname"
                    placeholder="Last Name"
                  >
                  </v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">Gender</span>

                  <v-radio-group dense v-model="userInfo.gender" mandatory>
                    <v-radio label="Male" value="Male"></v-radio>
                    <v-radio label="Female" value="Female"></v-radio>
                    <v-radio label="Other" value="Other"></v-radio>
                  </v-radio-group>
                </v-flex> -->
                <!-- <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">Date of birth</span>

                 
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dob"
                        placeholder="Birthday date"
                        prepend-icon="mdi-calendar"
                        readonly
                        required
                        :rules="[rules.required]"
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dob"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-flex> -->

                
                <v-flex xs12 px-3 pt-3 text-left>
                  <span class="sofiaPro">Address</span>

                  <v-textarea
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.streetAddress"
                    placeholder="Address"
                  >
                  </v-textarea>
                </v-flex>
                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">Pincode</span>

                  <v-text-field
                    dense
                    type="number"
                    :rules="[rules.required, rules.minpin]"
                    v-model="userInfo.pincode"
                    placeholder="Pincode"
                    @wheel="$event.target.blur()"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">City</span>

                  <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.city"
                    placeholder="City"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">District</span>

                  <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.district"
                    placeholder="District"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">State</span>
                  <v-select
                    v-model="userInfo.state"
                    :items="stateList"
                    item-text="name"
                    item-value="name"
                    placeholder="State"
                    :rules="[rules.required]"
                    
                    dense
                  ></v-select>

                  <!-- <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="userInfo.district"
                    placeholder="State"
                  >
                  </v-text-field> -->
                </v-flex>

                <v-flex xs12 sm6 px-3 text-left pt-3>
                  <span class="sofiaPro">Email</span>

                  <v-text-field
                    dense
                    :rules="[rules.email]"
                    v-model="userInfo.email"
                    placeholder="Email"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 px-3 pt-3 text-left>
                  <span class="sofiaPro">Phone Number</span>

                  <v-text-field
                    dense
                    type="number"
                    :rules="[rules.required, rules.min]"
                    v-model="userInfo.phone"
                    placeholder="Phone Number"
                    @wheel="$event.target.blur()"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm3 text-center py-7 px-3>
                  <v-btn
                    width="100%"
                    dark
                    block
                    :color="appColor"
                    @click="profileUpdate"
                  >
                    Update</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>
<script>
// import CoverImageComp from "./singleImage";
// import Propic from "./profileImage";
import axios from "axios";
export default {
  props: ["storage"],
  components: {
    // CoverImageComp,
    // Propic,
  },
  data() {
    return {
      sellerDet: {
        photo: null,
        coverPhoto: null,
        shopName: "",
        description: "",
        place: "",
        city: "",
        pincode: "",
        lat: "",
        lon: "",
        contactPerson: "",
        email: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
      },
      ServerError: false,
      conatctperson: null,
      instagram: null,
      facebook: null,
      Location: null,
      pic: null,
      pic2: null,
      cityy: null,
      locCity: null,
      state: null,
      pinn: null,
      shopName: null,
      email: null,
      whatsapp: null,
      contactPerson: null,
      lat: 0,
      lon: 0,
      sample: null,
      tab: null,
      changedlat: null,
      changedlon: null,
      sp: [],
      timeout: 3000,
      levelData: new FormData(),
      // level1: new formData(),

      msg: "",
      maps: null,
      showSnackBar: false,
      showCoverImage: "",
      coverImage: null,
      profileImage: null,
      coverData: new FormData(),
      appLoading: false,
      hiveGallery: [],
      gallery: new FormData(),
      description: "",
      name: "",
      phone: "",
      address: "",
      stateList: [],
      // rules: {
      //   required: (value) => !!value || "Required",
      //   min: (value) => value.length == 10 || "Invalid Phone",
      // },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 10 || "Max 10 digits",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        min: (value) => value.length == 10 || "Invalid Phone",
        minpin: (value) => value.length == 6 || "Invalid Pincode",
      },
      imageFiles: [],
      userInfo: {
        address: null,
        email: null,
        // dob: null,
        gender: null,
        firstname: null,
        lastname: null,
        phone: null,
      },
      imageArray: [],
      flag: true,
      currentLatt: null,
      currentLonn: null,
      l: "",
      n: "",
      activePicker: null,
      dob: null,
      menu: false,
    };
  },
  beforeMount() {
    this.getState();
    this.getUser();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
     getState() {
      axios({
        method: "GET",
        url: "/state/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status) {
            this.stateList = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    save(dob) {
      this.$refs.menu.save(dob);
    },
    getUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me/product",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status) {
          this.$store.commit("userData", response.data.data);
          this.$store.commit("userType", response.data.data.role);
          this.$store.commit("changeCart", response.data.cartcount);

          this.userInfo = response.data.data;
          // this.dob=this.userInfo.dob
          if (this.userInfo.photo) {
            this.profileImage = this.mediaURL + this.userInfo.photo;
            this.pic2 = this.profileImage;
          }
          if (this.userInfo.dob) {
            this.dob = new Date(this.userInfo.dob).toISOString().substr(0, 10);
          }
        } else {
          this.appLoading = false;

          // alert("failed to get email");
        }
      });
    },

    profilewinStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.profileImage = window_data.selectedFiles;
        this.pic2 = window_data.img;
      }
    },

    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImage = window_data.selectedFiles;

        this.pic = window_data.pic;
        this.coverImageUpload();
      }
    },
    galleryStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        this.imageFiles.splice(window_data.removalItem, 1);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "imageDeletion") {
        if (window_data.response.status) this.getUser();
      }
      if (window_data.getData) {
        this.getUser();
      }
    },

    unselect(value, item) {
      this.hiveGallery.splice(value, 1);
      this.urlgallery.splice(value, 1);
      this.sp = item;
      // console.log("the image name is", this.sp);
      this.deleteFomGallery(item);
    },
    chooseCoverImage() {
      document.getElementById("coverImage").click();
    },
    browseToGallery(img) {
      for (let i = 0; i < img.length; i++) {
        this.hiveGallery.push(img[i]);
        this.url7.push(URL.createObjectURL(this.hiveGallery));
      }

      // this.hiveGallery = file.target.files;
      // console.log("gallery is", this.hiveGallery);
      // console.log("hive gallary is=", this.hiveGallery);
      // this.uploadToGallery()
    },

    coverImageUpload() {
      this.coverData.append("uid", this.$route.query.seller);
      this.coverData.append("coverImage", this.coverImage);

      axios({
        method: "post",
        url: "/seller/coverImage/upload",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.coverData,
      }).then((response) => {
        if (response.data.status) {
          this.showCoverImage = response.data.coverImage;
          this.coverData = new FormData();
        } else {
          alert("failed to update cover image");
        }
      });
    },

    profileUpdate() {
      if (this.$refs.form.validate()) {
        // this.levelData.append("photo", this.profileImage);
        // this.levelData.append("coverPhoto", this.coverImage);
        this.levelData.append("firstname", this.userInfo.firstname);
        this.levelData.append("lastname", this.userInfo.lastname);
        this.levelData.append("email", this.userInfo.email);
        this.levelData.append("streetAddress", this.userInfo.streetAddress);
        this.levelData.append("phone", this.userInfo.phone);
        this.levelData.append("city", this.userInfo.city);
        this.levelData.append("pincode", this.userInfo.pincode);
        this.levelData.append("district", this.userInfo.district);
        this.levelData.append("state", this.userInfo.state);
        this.levelData.append("country", 'India');
        // this.levelData.append("dob", this.dob);

        axios({
          method: "post",
          url: "/customer/profile/update",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: this.levelData,
        })
          .then((response) => {
            if (response.data.status) {
              this.showSnackBar = true;
              this.msg = response.data.msg;
              this.$router.push("/");
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
            }
            this.getUser();
            this.appLoading = false;
            this.levelData = new FormData();
          })
          .catch((err) => {
            console.log(err);
            this.ServerError = true;
          });
      }
    },
    //new line of codes
  },
};
</script>

<style>
.center >>> input {
  text-align: center;
  font-family: robotobold;
}
.v-text-field >>> label {
  font-family: sofialight;
  color: red;
}
.v-text-field ::placeholder {
  font-family: poppinsregular !important;
  color: red;
}
.v-text-field--outlined {
  border-radius: 5px;
  font-family: sofialight;
  font-size: 18px;
}

#map44 {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
